const isLocalStorageAvailable = (): boolean => {
  try {
    localStorage.length;
    return true;
  } catch (e) {
    return false;
  }
};

export default isLocalStorageAvailable;
